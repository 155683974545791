<template>
  <b-dropdown v-if="isOwnerOrMaster" variant="light" class="ml-md-2 w-100">
    <template slot="button-content">
      <i class="uil uil-filter"></i>
      <span v-if="isAgentSelect">
        {{ selectedAgent }}
      </span>
      <span v-else>
        {{ $t('agent.name') }}
      </span>
      <i class="uil uil-angle-down"></i>
    </template>

    <b-dropdown-item-button
      v-for="(agent, agentI) in agentList"
      :key="agentI"
      @click="onSelectAgent(agent)"
    >
     {{ `(${agent.prefix}) ${agent.name}` }}
    </b-dropdown-item-button>
    <b-dropdown-divider />
    <b-dropdown-item-button @click="onSelectAgent({})">
      {{ $t('buttons.clear') }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FilterAgentsDropdown',
  props: {
    fieldName: {
      type: String,
      default: 'username',
    },
  },
  data() {
    return {
      selectedAgent: null,
    }
  },
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['isMaster', 'userInfo', 'agents', 'isOwner']),
    isOwnerOrMaster() {
      return this.isOwner || this.isMaster
    },
    agentList() {
      return this.agents.items || []
    },
    isAgentSelect() {
      if (this.selectedAgent.length > 0) {
        const found = this.agentList.find(
          (agent) => agent.id === this.selectedAgent.id
        )
        return !!found
      }
      return false
    },
  },
  watch: {
    selectedAgent(val) {
      if (val && this.fieldName === 'id') {
        const found = this.agentList.find((agent) => agent.id === val)
        if (found) {
          this.$emit('update', found.id)
        }
      } else {
        this.$emit('update', val)
      }
    },
  },
  methods: {
    onSelectAgent(agent) {
      this.selectedAgent = agent
    },
  },
}
</script>
